import { useRef } from 'react';

import { useParams } from 'react-router-dom';

import { Form } from '@blueprism/nextgen-platform-components';
import { useFormatDate } from '@blueprism/nextgen-localisation';

import { useActivatedProductApi } from 'pages/ProductConfiguration/hooks/';

import { useActivatedSkuByIdApi, useActivateSkuApi } from '../../hooks';
import { initialValues } from '../../constants';
import { ProductActivationDrawer } from '../ProductActivationDrawer';
import { useProductActivationDrawerContext } from '../../context';

import { ProductActivationFormContent } from './ProductActivationFormContent';
import { ProductViewFormContent } from './ProductViewFormContent';

import type { ProductConfigurationParams } from 'pages/ProductConfiguration/types/';
import type { ActivateProductFormData } from '../../types';
import type { FormikProps } from 'formik';

export function ProductActivationForm() {
  const { accountId, productId } = useParams<ProductConfigurationParams>();
  const formikRef = useRef<FormikProps<ActivateProductFormData>>(null);
  const { concurrencyStamp = '' } = useActivatedProductApi(accountId, productId);
  const { handleSubmit } = useActivateSkuApi(accountId, concurrencyStamp);
  const { drawerState } = useProductActivationDrawerContext();
  const { formatDate } = useFormatDate();

  const { isLoading, activatedSku } = useActivatedSkuByIdApi(drawerState?.skuId ?? '');

  const isSkuViewMode = !!drawerState?.skuId;

  const { from, to, licenses, meters, skuStatus } = activatedSku;

  const viewModeInitialValues = {
    startDate: formatDate(from),
    status: skuStatus,
    endDate: formatDate(to),
  };

  return (
    <ProductActivationDrawer formikRef={formikRef}>
      <Form
        viewMode={isSkuViewMode}
        loading={isLoading}
        initialValues={isSkuViewMode ? viewModeInitialValues : initialValues}
        onSubmit={handleSubmit}
        ref={formikRef}
      >
        {isSkuViewMode ? (
          <ProductViewFormContent licenses={licenses} meters={meters} />
        ) : (
          <ProductActivationFormContent />
        )}
      </Form>
    </ProductActivationDrawer>
  );
}
