import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { TenantStatus } from 'types/entities/Tenant';
import { useRegionContext } from 'services/region';

import { GET_TENANT_DETAILS } from './api';

import type { AccountDetailsBody, AccountDataResponse, GetAccountVariablesRequest } from './api';

export function useTenantDetailsApi(id: string) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix, managementRegionsLoading } = useRegionContext();

  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.GetTenantDetails, id, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<AccountDataResponse, GetAccountVariablesRequest>(managementRegionPrefix, GET_TENANT_DETAILS, {
        id,
      }),
    enabled: !!id && !!managementRegionPrefix,
  });

  const tenant = data?.tenant || ({} as AccountDetailsBody);
  const isLoading = isFetching || managementRegionsLoading;

  const {
    isAuthenticationConfigured = false,
    hasProductConfigured = false,
    isDomainConfigured = false,
    status = TenantStatus.PENDING,
  } = tenant;

  const isAdminUserCreated = Boolean(tenant?.id);

  const onboarding = {
    isAdminUserCreated,
    isAuthenticationConfigured,
    hasProductConfigured,
    isDomainConfigured,
    isTenantConfigured: isAdminUserCreated && isAuthenticationConfigured && hasProductConfigured && isDomainConfigured,
    isTenantActivated: status === TenantStatus.ACTIVE,
  };

  return { isLoading, tenant, onboarding };
}
