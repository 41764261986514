import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { useRegionContext } from 'services/region';
import { QueryKeys } from 'types/queries';

import { GET_ACTIVATED_PRODUCT } from './api';

import type { GetActivatedProductResponse, GetActivatedProductVariables } from './api';

export function useActivatedProductApi(tenantId: string, productId: string) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix } = useRegionContext();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetActivatedProduct, tenantId, productId, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<GetActivatedProductResponse, GetActivatedProductVariables>(
        managementRegionPrefix,
        GET_ACTIVATED_PRODUCT,
        {
          tenantId,
          productId,
        },
      ),
    enabled: !!tenantId && !!productId && !!managementRegionPrefix,
    throwOnError: false,
  });

  const product = data?.activatedProduct;

  return {
    productName: product?.name || '',
    concurrencyStamp: product?.concurrencyStamp || null,
    isProductLoading: isLoading,
  };
}
