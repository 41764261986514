import { gql } from 'graphql-request';

export const ACTIVATE_SKU = gql`
  mutation ActivateSku(
    $tenantId: Uuid!
    $productId: Uuid!
    $skuCode: String!
    $from: DateTime!
    $to: DateTime!
    $meterData: [MeterDataInput!]!
    $concurrencyStamp: Uuid
  ) {
    activateSku(
      input: {
        tenantId: $tenantId
        productId: $productId
        skuCode: $skuCode
        from: $from
        to: $to
        meterData: $meterData
        activatedProductConcurrencyStamp: $concurrencyStamp
      }
    ) {
      message
      entity {
        id
        skuStatus
      }
    }
  }
`;
