import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import { ThemeType, ThemeProvider } from '@blueprism/ui-core';
import { LanguageProvider } from '@blueprism/nextgen-localisation';
import { commonVocabulary } from '@blueprism/nextgen-platform-components';
import { tableVocabulary } from '@blueprism/nextgen-platform-table';
import { ToastContainer } from '@blueprism/ui-pattern-toasts';

import { Layout } from 'layout';
import { ConfigProvider } from 'services/config';
import { AuthProvider } from 'services/auth';
import { ApiProvider } from 'services/api';
import { RegionContextProvider } from 'services/region';
import { ApplicationRoutes } from 'routes';
import { vocabulary } from 'translations';

export function App() {
  return (
    <React.StrictMode>
      <ConfigProvider>
        <ThemeProvider theme={ThemeType.LIGHT}>
          <ToastContainer locale="en-US">
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <LanguageProvider locale="en" messages={[commonVocabulary, tableVocabulary, vocabulary]}>
                <AuthProvider>
                  {({ allowRender }) =>
                    allowRender && (
                      <ApiProvider>
                        <RegionContextProvider>
                          <Layout>
                            <ApplicationRoutes />
                          </Layout>
                        </RegionContextProvider>
                      </ApiProvider>
                    )
                  }
                </AuthProvider>
              </LanguageProvider>
            </BrowserRouter>
          </ToastContainer>
        </ThemeProvider>
      </ConfigProvider>
    </React.StrictMode>
  );
}
