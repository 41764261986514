import { gql } from 'graphql-request';

export const GET_TENANT_ADMIN_DETAILS = gql`
  query GetTenantAdminDetails($tenantId: Uuid!) {
    tenantAdminDetails(tenantId: $tenantId) {
      email
      firstName
      lastName
      id
    }
  }
`;
