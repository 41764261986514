import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { useRegionContext } from 'services/region';

import { GET_TENANT_ADMIN_STATUS } from './api';

import type { AccountAdminStatusDetails } from './types';
import type { AccountAdminDataResponse, GetAccountAdminVariablesRequest } from './api';

export function useAccountAdminStatusApi(tenantId: string) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix, managementRegionsLoading } = useRegionContext();

  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.GetAccountAdminStatus, tenantId, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<AccountAdminDataResponse, GetAccountAdminVariablesRequest>(
        managementRegionPrefix,
        GET_TENANT_ADMIN_STATUS,
        {
          tenantId,
        },
      ),
    enabled: !!tenantId && !!managementRegionPrefix,
  });

  const tenantAdminDetails = data?.tenantAdminDetails || ({} as AccountAdminStatusDetails);
  const isLoading = isFetching || managementRegionsLoading;

  return { isLoading, tenantAdminDetails };
}
