import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';
import { ToastType, useToast } from '@blueprism/ui-pattern-toasts';

import { useRegionContext } from 'services/region';
import { QueryKeys } from 'types/queries';

import { RESEND_TENANT_ADMIN_INVITATION_MUTATION } from './api';

import type { ResendInvitationResponse, ResendInvitationVariables } from './api';

export function useResendInvitationApi(tenantId: string) {
  const { formatMessage } = useIntl();
  const { triggerToast } = useToast();
  const queryClient = useQueryClient();
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix, managementRegionsLoading } = useRegionContext();

  const { mutateAsync, isPending: isResendInvitationLoading } = useMutation({
    mutationFn: (variables: ResendInvitationVariables) =>
      requestWithPrefix<ResendInvitationResponse>(
        managementRegionPrefix,
        RESEND_TENANT_ADMIN_INVITATION_MUTATION,
        variables,
      ),
  });

  const isLoading = isResendInvitationLoading || managementRegionsLoading;

  async function handleResendInvitation() {
    await mutateAsync({ tenantId });

    triggerToast({
      type: ToastType.SUCCESS,
      title: formatMessage({ id: 'account.invitation.notification.success.title' }),
      description: formatMessage({ id: 'account.invitation.notification.success.description' }),
    });

    queryClient.invalidateQueries({ queryKey: [QueryKeys.GetAccountAdminStatus, tenantId, managementRegionPrefix] });
  }

  return {
    handleResendInvitation,
    isLoading,
  };
}
