import { Route, Switch, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { RouterTabs } from '@blueprism/nextgen-platform-components';
import { useBreadcrumbs } from '@blueprism/nextgen-shared-hooks';
import { ApiErrorBoundary } from '@blueprism/nextgen-api';
import { PageLayout } from '@blueprism/ui-layouts';
import { Breadcrumbs, Link, Stack, Text } from '@blueprism/ui-core';

import { Routes } from 'routes/constants';
import { useRegionContext } from 'services/region';
import { ProductActivationFormDrawer, StyledPlaceholder, StyledTitleText } from 'components';

import { useTenantNameApi } from './hooks';
import { AccountDetails, Onboarding, Products } from './components';

import type { AccountDetailsParams } from 'types';

export const AccountPage = () => {
  const { formatMessage } = useIntl();
  const { accountId: id } = useParams<AccountDetailsParams>();
  const { selectedManagementRegion } = useRegionContext();

  const { tenantName = '', isLoading } = useTenantNameApi(id);

  const breadCrumbLinks = [
    { href: Routes.Account, title: formatMessage({ id: 'navigation.accountList.label' }) },
    { title: `${tenantName}`, dependOnLoading: true },
  ];

  const { breadcrumbs } = useBreadcrumbs(breadCrumbLinks, isLoading);

  const tabsInfo = [
    {
      label: formatMessage({ id: 'account.details.btn.accountDetails' }),
      route: Routes.getAccountDetailsUrl(selectedManagementRegion?.id, id),
    },
    {
      label: formatMessage({ id: 'account.details.btn.products' }),
      route: `${Routes.getAccountDetailsUrl(selectedManagementRegion?.id, id)}/products`,
    },
    {
      label: formatMessage({ id: 'account.details.btn.onboarding' }),
      route: `${Routes.getAccountDetailsUrl(selectedManagementRegion?.id, id)}/onboarding`,
    },
  ];

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs ariaLabel={formatMessage({ id: 'navigation.breadcrumbs.accountPage.ariaLabel' })}>
          {breadcrumbs.map(({ title, navigable, ...linkProps }) =>
            navigable ? (
              <Link {...linkProps} key={title}>
                {title}
              </Link>
            ) : (
              <Text key={title}>{title}</Text>
            ),
          )}
        </Breadcrumbs>
      }
      pageTitle={
        isLoading ? (
          <StyledPlaceholder width="16rem" height="3rem" />
        ) : (
          <StyledTitleText type="h4">{tenantName}</StyledTitleText>
        )
      }
      pageContent={
        <ApiErrorBoundary>
          <Stack gap="medium">
            <RouterTabs name="account-page-tabs" tabsInfo={tabsInfo} />
            <Switch>
              <Route exact path={Routes.Products}>
                <ApiErrorBoundary>
                  <ProductActivationFormDrawer>
                    <Products />
                  </ProductActivationFormDrawer>
                </ApiErrorBoundary>
              </Route>
              <Route exact path={Routes.Onboarding}>
                <Onboarding />
              </Route>
              <Route exact path={Routes.AccountDetail}>
                <AccountDetails />
              </Route>
            </Switch>
          </Stack>
        </ApiErrorBoundary>
      }
    />
  );
};
