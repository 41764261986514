import { gql } from 'graphql-request';

export const GET_ACTIVATED_SKU_BY_ID = gql`
  query ActivatedSkuById($tenantId: Uuid!, $productId: Uuid!, $skuId: Uuid!) {
    activatedSkuById(request: { productId: $productId, tenantId: $tenantId, skuId: $skuId }) {
      from
      to
      skuStatus
      licenses {
        displayName
      }
      meters {
        displayName
        limit
      }
    }
  }
`;
