import { gql } from 'graphql-request';

export const GET_TENANT_ADMIN_STATUS = gql`
  query GetTenantAdminStatus($tenantId: Uuid!) {
    tenantAdminDetails(tenantId: $tenantId) {
      id
      status
      dateInvitationLastSent
    }
  }
`;
