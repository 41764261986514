import { useIntl } from 'react-intl';

import { useBreadcrumbs } from '@blueprism/nextgen-shared-hooks';
import { PageLayout } from '@blueprism/ui-layouts';
import { Text, Breadcrumbs, Link } from '@blueprism/ui-core';

import { Routes } from 'routes/constants';

import { AccountCreationForm } from './AccountCreationForm';

export function AccountCreation() {
  const { formatMessage } = useIntl();

  const breadCrumbLinks = [
    { href: Routes.Account, title: formatMessage({ id: 'navigation.accountList.label' }) },
    { title: formatMessage({ id: 'navigation.createNewAccount.label' }) },
  ];

  const { breadcrumbs } = useBreadcrumbs(breadCrumbLinks);

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs ariaLabel={formatMessage({ id: 'navigation.breadcrumbs.accountCreation.ariaLabel' })}>
          {breadcrumbs.map(({ title, navigable, ...linkProps }) =>
            navigable ? (
              <Link {...linkProps} key={title}>
                {title}
              </Link>
            ) : (
              <Text key={title}>{title}</Text>
            ),
          )}
        </Breadcrumbs>
      }
      pageTitle={<Text type="h3">{formatMessage({ id: 'account.create.form.title' })}</Text>}
      pageContent={<AccountCreationForm />}
    />
  );
}
