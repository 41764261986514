import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Plus } from '@blueprism/ui-icons';
import { Text, Stack, Box, List, ToolbarButton } from '@blueprism/ui-core';
import { useFormatDate } from '@blueprism/nextgen-localisation';
import { Table, useTable } from '@blueprism/nextgen-platform-table';
import type { TableColumns } from '@blueprism/nextgen-platform-table';

import { useProductActivationDrawerContext } from 'components';

import { StyledListItem, StyledStack } from './ProductConfigurationPage.styled';
import { INITIAL_SORTING_OPTIONS } from './constants';
import { useActivatedSkusByProduct } from './hooks';
import { formatSkuStatus, getTranslatedProductLicensesList } from './formatters';

import type { ProductSku } from './hooks/useActivatedSkusByProduct/api';
import type { Product } from 'types/entities';
import type { ProductConfigurationParams } from './types';

export function ProductConfigurationView({ name }: Pick<Product, 'name'>) {
  const { formatMessage } = useIntl();
  const { openDrawer } = useProductActivationDrawerContext();
  const { formatDate } = useFormatDate();

  const { accountId, productId } = useParams<ProductConfigurationParams>();

  const tableColumns: TableColumns<ProductSku> = {
    name: {
      order: 1,
      header: formatMessage({ id: 'account.productConfiguration.table.sku' }),
      width: '25%',
    },
    skuStatus: {
      order: 2,
      header: formatMessage({ id: 'account.productConfiguration.table.status' }),
      tableCell: (skuStatus) => formatSkuStatus(skuStatus, formatMessage),
      width: '25%',
    },
    from: {
      order: 3,
      header: formatMessage({ id: 'account.productConfiguration.table.from' }),
      tableCell: (from) => formatDate(from),
      width: '25%',
    },
    to: {
      order: 4,
      header: formatMessage({ id: 'account.productConfiguration.table.to' }),
      tableCell: (to) => formatDate(to),
      width: '25%',
    },
  };

  function handleAddSku() {
    openDrawer({ id: productId });
  }

  function handleViewSku({ id, name: skuName = '' }: Partial<ProductSku>) {
    openDrawer({ header: skuName, name: skuName, id: productId, skuId: id });
  }

  const { queryVariables, createPagingOptions, ...restTableProps } = useTable({
    tableConfig: {
      columns: tableColumns,
      selectRows: true,
      defaultSortingOptions: INITIAL_SORTING_OPTIONS,
    },
    toolbarConfig: {
      customContextualButtons: [
        <ToolbarButton
          key={1}
          onClick={handleAddSku}
          label={formatMessage({ id: 'account.productConfiguration.addSku.button' })}
          icon={<Plus />}
        />,
      ],
    },
  });

  const { listData, isLoading, pagingOptions } = useActivatedSkusByProduct({
    createPagingOptions,
    variables: { ...queryVariables, request: { tenantId: accountId, productId } },
  });

  const productLicenses = getTranslatedProductLicensesList(listData, formatMessage);

  return (
    <StyledStack gap="xl">
      <Stack gap="base">
        <Text>
          <strong>{formatMessage({ id: 'account.productConfiguration.subtitle.description' })}</strong>
        </Text>
        <Box>
          <Text>{formatMessage({ id: 'account.productConfiguration.description' }, { productName: name })}</Text>
          <List>
            {productLicenses.sort().map((displayLicenseName) => (
              <StyledListItem key={displayLicenseName}>{displayLicenseName}</StyledListItem>
            ))}
          </List>
        </Box>
      </Stack>

      <Stack>
        <Table
          title={<Text type="h5">{formatMessage({ id: 'account.productConfiguration.table.title' })}</Text>}
          rowId="id"
          isLoading={isLoading}
          onView={handleViewSku}
          data={listData}
          pagingOptions={pagingOptions}
          {...restTableProps}
        />
      </Stack>
    </StyledStack>
  );
}
