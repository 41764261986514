import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { useRegionContext } from 'services/region';

import { GET_ACTIVATED_SKU_BY_ID } from './api';

import type { ProductSku } from './api/types';
import type { GetActivatedSkuByIdResponse, GetActivatedSkuByIdVariablesRequest } from './api';
import type { ProductConfigurationParams } from 'pages/ProductConfiguration/types';

export function useActivatedSkuByIdApi(skuId: string) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix } = useRegionContext();

  const { accountId: tenantId, productId } = useParams<ProductConfigurationParams>();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetActivatedSkuById, tenantId, productId, skuId, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<GetActivatedSkuByIdResponse, GetActivatedSkuByIdVariablesRequest>(
        managementRegionPrefix,
        GET_ACTIVATED_SKU_BY_ID,
        {
          tenantId,
          productId,
          skuId,
        },
      ),
    enabled: !!skuId && !!tenantId && !!productId && !!managementRegionPrefix,
  });

  const activatedSku = data?.activatedSkuById || ({} as ProductSku);

  return { isLoading, activatedSku };
}
