import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { useRegionContext } from 'services/region';

import { GET_PRODUCTS } from './api';

import type { ProductGroups } from './api/types';
import type { GetProductsResponse, GetProductsVariablesRequest } from './api';

export function useProductsApi(tenantId: string) {
  const { requestWithPrefix } = useApiClient();
  const { managementRegionPrefix, managementRegionsLoading } = useRegionContext();

  const initialProducts = { activatedProducts: [], notActivatedProducts: [] };

  const { data = initialProducts, isFetching } = useQuery({
    queryKey: [QueryKeys.GetTenantProducts, tenantId, managementRegionPrefix],
    queryFn: () =>
      requestWithPrefix<GetProductsResponse, GetProductsVariablesRequest>(managementRegionPrefix, GET_PRODUCTS, {
        tenantId,
      }),
    enabled: !!tenantId && !!managementRegionPrefix,
    select: (response: GetProductsResponse) => ({
      ...response?.products?.edges.reduce((acc: ProductGroups, { node }) => {
        const group = node?.isActivatedInTenant ? 'activatedProducts' : 'notActivatedProducts';
        acc[group].push(node);

        return acc;
      }, initialProducts),
    }),
  });

  const isLoading = isFetching || managementRegionsLoading;

  return {
    isLoading,
    activatedProducts: data.activatedProducts,
    notActivatedProducts: data.notActivatedProducts,
  };
}
