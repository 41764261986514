import { gql } from 'graphql-request';

export const GET_ACTIVATED_PRODUCT = gql`
  query ActivatedProduct($tenantId: Uuid!, $productId: Uuid!) {
    activatedProduct(request: { tenantId: $tenantId, productId: $productId }) {
      concurrencyStamp
      name
    }
  }
`;
