export enum AccountAdminStatus {
  UNKNOWN = 'UNKNOWN',
  INVITATION_REQUESTED = 'INVITATION_REQUESTED',
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  INVITE_FAILED = 'INVITE_FAILED',
  INVITATION_RESENT = 'INVITATION_RESENT',
  TENANT_ADMIN_CREATION_REQUESTED = 'TENANT_ADMIN_CREATION_REQUESTED',
  TENANT_ADMIN_CREATED = 'TENANT_ADMIN_CREATED',
  ACTIVATION_REQUESTED = 'ACTIVATION_REQUESTED',
  DEACTIVATION_REQUESTED = 'DEACTIVATION_REQUESTED',
}

export interface AccountAdmin {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  status: AccountAdminStatus;
  dateInvitationLastSent: string;
}
