import { Switch, Redirect, Route } from 'react-router-dom';

import { Account, AccountCreation, ProductConfiguration, TenantList } from 'pages';

import { Routes } from './constants';

export function ApplicationRoutes() {
  return (
    <Switch>
      <Route path={Routes.AccountAdd}>
        <AccountCreation />
      </Route>
      <Route exact path={Routes.ProductConfiguration}>
        <ProductConfiguration />
      </Route>
      <Route path={Routes.AccountDetail}>
        <Account />
      </Route>
      <Route exact path={Routes.Account}>
        <TenantList />
      </Route>
      <Redirect from="*" to={Routes.Account} />
    </Switch>
  );
}
